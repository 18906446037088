
import { Link } from "react-router-dom";
import { LogIn } from "lucide-react";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { cn } from "@/lib/utils";

interface MobileNavProps {
  isOpen: boolean;
  scrollToSection: (id: string) => void;
  onClose: () => void;
}

const MobileNav = ({ isOpen, scrollToSection, onClose }: MobileNavProps) => {
  const location = useLocation();
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const isHomePage = location.pathname === "/";
  
  // Reset active section when changing pages
  useEffect(() => {
    setActiveSection(null);
  }, [location.pathname]);
  
  if (!isOpen) return null;

  const handleSectionClick = (section: string) => {
    setActiveSection(section);
    scrollToSection(section);
    onClose();
  };

  return (
    <div className="md:hidden fixed inset-x-0 top-20 bg-white shadow-lg">
      <div className="px-4 py-6 space-y-5">
        <div className="space-y-3">
          {isHomePage ? (
            <>
              <button
                onClick={() => handleSectionClick("hero")}
                className={`relative w-full text-left px-4 py-2 rounded-full transition-all duration-200 demo-request-button ${
                  activeSection === "hero" || (!activeSection && isHomePage)
                    ? "text-white after:opacity-100" 
                    : "text-primary/70 hover:text-white"
                }`}
              >
                Home
              </button>
            </>
          ) : (
            <>
              <Link
                to="/"
                className="relative block px-4 py-2 rounded-full text-primary/70 hover:text-white demo-request-button"
                onClick={onClose}
              >
                Home
              </Link>
            </>
          )}
          <Link
            to="/pricing"
            className={`relative block px-4 py-2 rounded-full transition-all duration-200 demo-request-button ${
              location.pathname === "/pricing" 
                ? "text-white after:opacity-100" 
                : "text-primary/70 hover:text-white"
            }`}
            onClick={onClose}
          >
            Pricing
          </Link>
          <Link
            to="/blog"
            className={`relative block px-4 py-2 rounded-full transition-all duration-200 demo-request-button ${
              location.pathname === "/blog" 
                ? "text-white after:opacity-100" 
                : "text-primary/70 hover:text-white"
            }`}
            onClick={onClose}
          >
            Blog
          </Link>
        </div>
        <div className="pt-4 border-t border-gray-100">
          <a
            href="https://app.propertyvue.io/auth/login"
            className={cn(
              "flex items-center justify-center gap-2 w-full",
              "demo-request-button relative appearance-none cursor-pointer",
              "px-6 py-2.5 rounded-full",
              "text-primary font-bold border-2 border-primary/30",
              "transition-all duration-300"
            )}
            onClick={onClose}
          >
            <LogIn size={18} />
            <span>Sign In</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default MobileNav;
