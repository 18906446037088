
import { Tabs } from "@/components/ui/tabs";
import { FeaturesTabs } from "./features/FeaturesTabs";
import { TabsContainer } from "./features/tabs/TabsContainer";

export const FeaturesSection = () => {
  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <section id="features" className="py-12 sm:py-16 bg-white">
      <div className="max-w-5xl mx-auto px-3 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center justify-between gap-4 sm:gap-6">
          <Tabs defaultValue="integrations" className="w-full">
            <div className="hidden">
              <FeaturesTabs onTabChange={scrollToSection} />
            </div>
            <TabsContainer />
          </Tabs>
        </div>
      </div>
    </section>
  );
};
