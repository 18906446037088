interface SiteUploadsFeatureProps {
  videoUrl: string;
  isLoading: boolean;
}

export const SiteUploadsFeature = ({ videoUrl, isLoading }: SiteUploadsFeatureProps) => {
  return (
    <div 
      id="site-uploads" 
      className="flex flex-col md:flex-row items-center justify-between gap-8 sm:gap-16 scroll-mt-24 sm:scroll-mt-32"
    >
      <div className="w-full md:w-1/2 px-4 sm:px-0">
        <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 text-center md:text-left">
          Effortless Site Photo Management
        </h2>
        <p className="text-sm sm:text-base text-gray-600 text-center md:text-left mb-3 sm:mb-4">
          Upload and organize your construction site photos with ease. Whether you're documenting progress or identifying issues, our platform helps you maintain a clear visual record of your project's development—all accessible from any device.
        </p>
      </div>
      <div className="w-full md:w-1/2 flex justify-center">
        <div className="w-full max-w-2xl md:w-[80%]">
          {isLoading ? (
            <div className="w-full aspect-video bg-gray-50 animate-pulse rounded-lg flex items-center justify-center">
              <span className="text-gray-400">Loading...</span>
            </div>
          ) : (
            <div className="w-full aspect-video bg-gray-50 rounded-lg flex items-center justify-center">
              <span className="text-gray-400">Animation placeholder</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};