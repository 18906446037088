
import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";

export const useSupabaseImage = (path: string, expirySeconds: number = 3600) => {
  const [imageUrl, setImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchImageUrl = async () => {
      setIsLoading(true);
      console.log(`Fetching image URL for path: ${path}`);
      try {
        // Try media bucket first
        const { data: mediaSignedUrlData, error: mediaError } = await supabase.storage
          .from('media')
          .createSignedUrl(path, expirySeconds);
        
        if (mediaError) {
          console.log(`Not found in media bucket, trying app_media bucket for path: ${path}`);
          // Try app_media bucket as fallback
          const { data: signedUrlData, error } = await supabase.storage
            .from('app_media')
            .createSignedUrl(path, expirySeconds);
          
          if (error) {
            console.error("Error getting signed URL from both buckets:", error);
            return;
          }
          
          if (signedUrlData?.signedUrl) {
            console.log(`Successfully got signed URL from app_media bucket for image: ${path}`);
            setImageUrl(signedUrlData.signedUrl);
          }
        } else if (mediaSignedUrlData?.signedUrl) {
          console.log(`Successfully got signed URL from media bucket for image: ${path}`);
          setImageUrl(mediaSignedUrlData.signedUrl);
        } else {
          console.log("No signed URL found in either bucket");
        }
      } catch (err) {
        console.error("Failed to fetch image URL:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchImageUrl();
  }, [path, expirySeconds]);

  return { imageUrl, isLoading };
};
