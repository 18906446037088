import { motion } from "framer-motion";
import { PricingTier } from "./PricingTier";
import type { PricingTierType } from "@/hooks/usePricingTiers";

interface PricingGridProps {
  pricingTiers: PricingTierType[];
}

const PricingGrid = ({ pricingTiers }: PricingGridProps) => {
  const handleStartFreeTrial = () => {
    console.log('Starting free trial');
    window.location.href = 'https://app.propertyvue.io/auth/login';
  };

  return (
    <div className="space-y-12">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className="relative max-w-7xl mx-auto"
      >
        <div className="mb-12 text-center">
          <span className="inline-block bg-primary/10 text-primary px-6 py-2.5 rounded-full text-sm font-medium shadow-sm">
            First user free on Teams & Scale plans
          </span>
        </div>
        
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {pricingTiers?.map((tier, index) => (
            <PricingTier 
              key={tier.id}
              name={tier.name}
              price={tier.price}
              description={tier.description}
              features={tier.features}
              isPopular={index === 1}
            />
          ))}
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4 }}
        className="flex justify-center mt-12"
      >
        <button 
          onClick={handleStartFreeTrial}
          className="bg-primary text-white py-3 px-8 rounded-xl transition-all duration-300 hover:bg-primary/90 shadow-lg shadow-primary/20 hover:shadow-xl hover:shadow-primary/30 text-lg font-semibold"
        >
          Start Free Trial
        </button>
      </motion.div>
    </div>
  );
};

export default PricingGrid;