import { TabsContent } from "@/components/ui/tabs";
import { CloudIntegrationFeature } from "../CloudIntegrationFeature";
import { DailyLogsFeature } from "../DailyLogsFeature";
import { SiteUploadsFeature } from "../SiteUploadsFeature";
import { useSupabaseVideo } from "@/hooks/useSupabaseVideo";

export const TabsContainer = () => {
  const { videoUrl: integrationVideoUrl, isLoading: isVideoLoading } = useSupabaseVideo('integrations.mp4', 60 * 60);
  const { videoUrl: dailyLogsVideoUrl, isLoading: isDailyLogsLoading } = useSupabaseVideo('daily_logs.mp4', 60 * 60);
  const { videoUrl: siteUploadsVideoUrl, isLoading: isSiteUploadsLoading } = useSupabaseVideo('site_uploads.mp4', 60 * 60);

  return (
    <div>
      <TabsContent value="integrations">
        <CloudIntegrationFeature 
          videoUrl={integrationVideoUrl} 
          isLoading={isVideoLoading} 
        />
      </TabsContent>
      
      <div className="hidden">
        <TabsContent value="daily-logs">
          <DailyLogsFeature 
            videoUrl={dailyLogsVideoUrl}
            isLoading={isDailyLogsLoading}
          />
        </TabsContent>
        
        <TabsContent value="site-uploads">
          <SiteUploadsFeature 
            videoUrl={siteUploadsVideoUrl}
            isLoading={isSiteUploadsLoading}
          />
        </TabsContent>
      </div>
    </div>
  );
};