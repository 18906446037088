export const faqs = [
  {
    question: "How do I get started with Property Vue?",
    answer:
      "New users can choose a plan, start a free 7 day trial, and start using the platform immediately.",
  },
  {
    question: "Can multiple users access the same account?",
    answer:
      "Yes, multiple users can share access on the Teams and Scale plans, making team collaboration seamless.",
  },
  {
    question: "How do the Google Drive and Dropbox integrations work?",
    answer:
      "It's easy! Go to Settings, click \"Connect\" for Google Drive or Dropbox, and select the folders you want to sync. Images from those folders will sync automatically.",
  },
  {
    question: "How does the SMS integration work?",
    answer:
      "Easy! Go to settings, click \"Verify Number\" and send the verification code. Once verified, all images you text will sync.",
  },
  {
    question: "What happens if I accidentally delete something?",
    answer:
      "If you delete something by accident, contact us as soon as possible. While we'll do our best to help, recovery cannot be guaranteed.",
  },
  {
    question: "Is there a mobile app?",
    answer:
      "There's no mobile app yet, but the platform is fully optimized for mobile browsers, and all features are accessible on mobile devices.",
  },
  {
    question: "Can I switch between plans?",
    answer:
      "Yes, you can upgrade or downgrade your plan at any time to suit your team's needs.",
  },
  {
    question: "Is Property Vue secure?",
    answer:
      "Yes, Property Vue meets industry standards for safety and security, ensuring your data is protected.",
  },
  {
    question: "What support can I expect?",
    answer:
      "We handle all support requests within 24 hours, so you can get the help you need quickly and efficiently.",
  },
  {
    question: "What's included in each pricing plan?",
    answer:
      "The Starter plan offers essential features for single users, while Teams and Scale plans unlock team access, integrations, and priority support. Full details are available on the pricing page.",
  },
];
