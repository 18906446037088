import { Badge } from "@/components/ui/badge";
import { PricingFeatureItem } from "./PricingFeatureItem";
import { PricingDisplay } from "./PricingDisplay";

interface Feature {
  name: string;
  included: boolean;
}

type PlanName = 'Starter' | 'Teams' | 'Scale';

interface PricingTierProps {
  name: PlanName;
  price: number;
  description: string | null;
  features: Feature[];
  isPopular?: boolean;
}

export const PricingTier = ({
  name,
  price,
  description,
  features,
  isPopular,
}: PricingTierProps) => {
  return (
    <div 
      className={`
        relative flex flex-col p-8 rounded-2xl shadow-lg border 
        transition-all duration-300 group
        bg-white hover:bg-primary text-primary hover:text-white
        ${isPopular ? 'border-primary/30 shadow-primary/10 scale-105' : 'border-gray-100 hover:border-primary'}
      `}
    >
      {isPopular && (
        <Badge className="absolute -top-3 left-1/2 -translate-x-1/2 bg-primary text-white group-hover:bg-white group-hover:text-primary px-4 py-1">
          Most Popular
        </Badge>
      )}
      <h3 className="text-2xl font-bold">{name}</h3>
      <p className="mt-2 text-gray-600 group-hover:text-white/80 text-sm">{description}</p>
      
      <PricingDisplay price={price} />
      
      <ul className="mt-8 space-y-4 flex-1">
        {features.map((feature) => (
          <PricingFeatureItem 
            key={feature.name}
            name={feature.name}
            included={feature.included}
          />
        ))}
      </ul>
    </div>
  );
};