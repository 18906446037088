
import { ChevronRight } from "lucide-react";
import { DemoRequestForm } from "@/components/DemoRequestForm";
import { GradientButton } from "@/components/ui/gradient-button";

export const HeroContent = () => {
  const handleStartFreeTrial = () => {
    window.location.href = 'https://app.propertyvue.io/auth/login';
  };

  return (
    <div className="text-center md:text-left space-y-4 md:space-y-6">
      <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-primary leading-tight">
        Bring Order to the Chaos of Construction Photos
      </h1>
      <p className="text-base md:text-lg text-primary/70 max-w-xl mx-auto md:mx-0">
        Organize all your job site images into one platform, making them easy to find, share, and use.
      </p>
      <div className="flex flex-col sm:flex-row items-center justify-center md:justify-start gap-6 sm:gap-4">
        <GradientButton 
          onClick={handleStartFreeTrial}
          className="relative w-full sm:w-auto px-6 md:px-8 py-3 rounded-xl flex items-center justify-center"
        >
          <span className="relative z-10 flex items-center">
            Start Free Trial
            <ChevronRight className="ml-2" size={20} />
          </span>
        </GradientButton>
        <div className="w-full sm:w-auto">
          <DemoRequestForm />
        </div>
      </div>
    </div>
  );
};
