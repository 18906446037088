import { useQuery } from "@tanstack/react-query";

export interface PricingTierType {
  id: string;
  name: 'Starter' | 'Teams' | 'Scale';
  price: number;
  description: string | null;
  features: { name: string; included: boolean; }[];
}

const mockPricingTiers: PricingTierType[] = [
  {
    id: "1",
    name: "Starter",
    price: 11.99,
    description: "Perfect for small teams just getting started",
    features: [
      { name: "Single user", included: true },
      { name: "Access to core features", included: true },
      { name: "Google Drive integration", included: false },
      { name: "Dropbox integration", included: false },
      { name: "SMS integration", included: false }
    ]
  },
  {
    id: "2",
    name: "Teams",
    price: 29.99,
    description: "Great for growing construction teams",
    features: [
      { name: "Up to 6 users", included: true },
      { name: "Access to core features", included: true },
      { name: "Google Drive integration", included: true },
      { name: "Dropbox integration", included: false },
      { name: "SMS integration", included: true }
    ]
  },
  {
    id: "3",
    name: "Scale",
    price: 49.99,
    description: "For large enterprises needing full features",
    features: [
      { name: "Unlimited users", included: true },
      { name: "Access to core features", included: true },
      { name: "Google Drive integration", included: true },
      { name: "Dropbox integration", included: true },
      { name: "SMS integration", included: true }
    ]
  }
];

export const usePricingTiers = () => {
  return useQuery({
    queryKey: ['pricing-tiers'],
    queryFn: async () => mockPricingTiers,
  });
};