
import { Link, useLocation } from "react-router-dom";
import NavLink from "./NavLink";
import { LogIn } from "lucide-react";
import { useState, useEffect } from "react";
import { cn } from "@/lib/utils";
import { useResponsive } from "@/hooks/useResponsive";

interface DesktopNavProps {
  scrollToSection: (id: string) => void;
}

const DesktopNav = ({ scrollToSection }: DesktopNavProps) => {
  const location = useLocation();
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const isHomePage = location.pathname === "/";
  const { isMobile } = useResponsive();
  
  // Reset active section when changing pages
  useEffect(() => {
    setActiveSection(null);
  }, [location.pathname]);
  
  const handleSectionClick = (section: string) => {
    setActiveSection(section);
    scrollToSection(section);
  };
  
  return (
    <div className="hidden md:flex items-center justify-between w-full">
      <div className="w-40" />

      <div className="absolute left-1/2 -translate-x-1/2">
        <div className="bg-primary/[0.02] border border-primary/[0.05] rounded-full p-1 flex items-center gap-2">
          {isHomePage ? (
            <>
              <button
                onClick={() => handleSectionClick("hero")}
                className={`relative px-4 py-2 rounded-full transition-all duration-200 demo-request-button ${
                  activeSection === "hero" || (!activeSection && isHomePage)
                    ? "text-white after:opacity-100" 
                    : "text-primary/70 hover:text-white"
                }`}
              >
                Home
              </button>
            </>
          ) : (
            <>
              <Link
                to="/"
                className="relative px-4 py-2 rounded-full text-primary/70 hover:text-white demo-request-button"
              >
                Home
              </Link>
            </>
          )}
          <NavLink href="/pricing">Pricing</NavLink>
          <NavLink href="/blog">Blog</NavLink>
        </div>
      </div>

      {/* Right section - auth button */}
      <div className="flex items-center">
        <a 
          href="https://app.propertyvue.io/auth/login"
          className={cn(
            "demo-request-button relative appearance-none cursor-pointer",
            "px-6 py-2.5 rounded-full",
            "text-primary font-bold border-2 border-primary/30",
            "transition-all duration-300 flex items-center justify-center",
            "hover:text-white gap-2"
          )}
        >
          <LogIn size={18} />
          <span>Sign In</span>
        </a>
      </div>
    </div>
  );
};

export default DesktopNav;
