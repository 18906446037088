import { motion } from "framer-motion";

const PricingHeader = () => {
  return (
    <div className="max-w-7xl mx-auto text-center mb-8">
      <motion.h1 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-4xl font-bold text-primary sm:text-5xl"
      >
        Clear Pricing for Every Team
      </motion.h1>
      <motion.p 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1 }}
        className="mt-6 text-xl text-gray-600 max-w-2xl mx-auto"
      >
        Find the perfect plan to organize your construction projects and simplify image management. Choose what works best for your team, no hidden fees.
      </motion.p>
    </div>
  );
};

export default PricingHeader;